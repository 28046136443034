#smallpic {
  cursor: pointer;
}

#smallpic:hover {
  border-bottom: 1px solid #ddd;
}

#bigpic {
  position: relative;
  overflow: hidden;
}
#card-img-top-1 {
  transition: all 0.5s linear;
}
#bigpic:hover #card-img-top-1 {
  transform: scale(2);
}

#alertCart {
  width: 300px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position:fixed;
  top: 30px;
  right: 20px;
  z-index: 99999;
  opacity: 0.8;
}
